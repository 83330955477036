import {
  useToast,
  type StringIndexedObject,
} from '@kanda-libs/ks-design-library';
import {
  actions,
  services,
  useSubmit,
  type Service,
} from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCurrentCompany, useMe } from 'hooks';
import useApiError from 'hooks/useApiError';
import { useCallback, useMemo, useState } from 'react';
import { useForm, type UseFormReturn } from 'react-hook-form';
import { scrollToTop } from 'utils';
import { BASE_ERROR_MESSAGES } from './constants';
import { useSubscriptionContext, type Contract } from './context';
import { formatContractName } from './helpers';
import type { FieldValues } from './types';

export interface PayMonthlyFormHook {
  form: UseFormReturn<FieldValues>;
  onSubmit: (formValues: StringIndexedObject<FieldValues>) => void;
  disabled: boolean;
  isSubmitting: boolean;
}

export default function usePayMonthlyForm(): PayMonthlyFormHook {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { setContract, setBankAccount, setFormDisplay } =
    useSubscriptionContext();
  const { me } = useMe();
  const { company } = useCurrentCompany();
  const { showError } = useToast();
  const dispatch = useAppDispatch();

  const onError = useApiError('Issue uploading generated contract');

  const { submit: writeSheet } = useSubmit(
    services.subsSheet.write as unknown as Service<
      StringIndexedObject,
      StringIndexedObject,
      StringIndexedObject
    >,
  );

  const { submit: generateGuarantee } = useSubmit(
    services.personalGuarantee.generate as unknown as Service<
      StringIndexedObject,
      StringIndexedObject,
      StringIndexedObject
    >,
  );

  const defaultValues = useMemo(
    () => ({
      guarantor: {
        name: me?.name,
        bank_account: {
          account_name: company?.bank_account?.account_name || '',
          account_number: company?.bank_account?.account_number || '',
          sort_code: company?.bank_account?.sort_code || '',
        },
      },
      checks: {
        authorised: {
          value: false,
        },
      },
    }),
    [me, company],
  );

  const form = useForm<FieldValues>({
    mode: 'onBlur',
    defaultValues,
  });

  const onSubmit = useCallback(
    async (fv: StringIndexedObject<FieldValues>) => {
      const formValues = fv as unknown as FieldValues;
      const { guarantor, checks } = formValues;
      const sheetWriteBody = {
        company,
        guarantor,
        user: me,
      };
      const guaranteeBody = {
        company,
        guarantor,
        signee: guarantor.name,
        fingerprint: {
          value: checks.authorised.value,
          timestamp: checks.timestamp,
          ip: checks.ip,
        },
      };
      const cid = company?.id;

      if (!cid) return;

      setIsSubmitting(true);

      const promises = Promise.all([
        writeSheet({ body: sheetWriteBody }),
        generateGuarantee({
          body: guaranteeBody,
        }),
      ]);

      promises
        .then((values) => {
          setIsSubmitting(false);

          const errors = values.map((val) =>
            Boolean((val as unknown as any)?.error),
          );
          if (errors.some((err) => err)) {
            const message = BASE_ERROR_MESSAGES.filter(
              (_, index: number) => errors[index],
            ).join(' ');
            showError(
              `${message}. Please refresh and try again or contact Kanda if the problem persists.`,
            );
            return;
          }
          const contractData = values?.[1]?.data;
          if (!contractData) {
            showError(
              `${BASE_ERROR_MESSAGES[1]}. Please refresh and try again or contact Kanda if the problem persists.`,
            );
            return;
          }
          const contract: Contract = {
            name: contractData.data.name,
            content: contractData.data.content,
            signee: guarantor.name,
            timestamp: checks.timestamp,
          };

          const name = `UNSIGNED-PREMIUMCREDIT_${formatContractName(contract)}`;

          setBankAccount(guarantor.bank_account);
          setContract(contract);
          dispatch(
            actions.postDocument({
              body: {
                name,
                content: contract.content,
              },
              onError,
            }),
          );
          scrollToTop();
          setFormDisplay('contract');
          setIsSubmitting(false);
        })
        .catch(() => {
          setIsSubmitting(false);
          showError(
            `An unknown error has occurred. Please refresh and try again or contact Kanda if the problem persists.`,
          );
        });
    },
    [
      company,
      me,
      writeSheet,
      showError,
      generateGuarantee,
      setBankAccount,
      setContract,
      setFormDisplay,
      onError,
      dispatch,
    ],
  );

  const [authorised] = form.watch(['checks.authorised.value']);

  const disabled = useMemo(() => {
    if (isSubmitting) return true;
    return !authorised;
  }, [authorised, isSubmitting]);

  return {
    form,
    onSubmit,
    disabled,
    isSubmitting,
  };
}
